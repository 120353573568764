export default {
  default: 'th',
  SystemBar: {
    call: 'โทร',
    time: 'จันทร์- ศุกร์ เวลา 08.00-17.00 น. หยุด เสาร์ อาทิตย์'
  },
  Home: {
    serchBarText: 'ค้นหาสินค้า',
    AllCategories: 'หมวดหมู่สินค้า',
    SearchText: 'ค้นหา',
    HomeText: 'หน้าหลัก',
    AboutUsText: 'เกี่ยวกับร้านค้า',
    HowToBuyText: 'วิธีการสั่งซื้อ',
    ArticlesText: 'บทความทั้งหมด',
    ContactText: 'ติดต่อร้านค้า',
    CampaingPromotion: 'แคมเปญและโปรโมชัน',
    category: 'หมวดหมู่สินค้า',
    buyAgain: 'สั่งซื้ออีกครั้ง',
    seeAll: 'ดูทั้งหมด',
    New: 'สินค้ามาใหม่',
    Hot: 'สินค้าขายดี',
    Recommended: 'สินค้าแนะนำ',
    Promotion: 'โปรโมชัน',
    Campaing: 'แคมเปญ',
    NewsAndActivity: 'บทความ',
    NewsAndActivityAbout: 'บทความที่เกี่ยวข้อง',
    ShoppingCart: 'รถเข็น',
    CheckOut: 'ยืนยันคำสั่งซื้อ',
    ViewMyShoppingCart: 'ดูรถเข็นของคุณ',
    NoProductsYet: 'ยังไม่มีสินค้าในรถเข็น',
    GotoShopping: 'ช้อปปิ้งกันเลย',
    YOUMAYALSOLIKE: 'สินค้าที่คุณอาจจะชอบ',
    Voucher: 'เวาเชอร์',
    UnpaidOrder: 'คำสั่งซื้อยังไม่ได้ชำระเงิน',
    TrackingOrder: 'ติดตามสถานะการจัดส่ง',
    RequestForQuotation: 'ขอใบเสนอราคา',
    ToRate: 'ให้คะแนนคำสั่งซื้อ',
    Comment: 'ความคิดเห็น / ความพึงพอใจจากผู้ซื้อ',
    CommentBr: 'ความคิดเห็น',
    CustomerSatisfaction: 'ความพึงพอใจจากผู้ซื้อ'
  },
  Footer: {
    AddressText: 'ที่อยู่',
    GetToKnowUs: 'หัวข้อ',
    head1: 'เกี่ยวกับร้านค้า',
    head2: 'วิธีการสั่งซื้อ',
    head3: 'การสั่งซื้อของฉัน',
    head4: 'แต้มร้านค้า',
    head5: 'บทความ',
    head6: 'ติดต่อร้านค้า',
    head7: 'ข้อกำหนดของบริการ',
    head8: 'การลบข้อมูลผู้ใช้',
    head9: 'นโยบายความเป็นส่วนตัว',
    Payment: 'ช่องทางการชำระเงิน',
    Contact: 'แหล่งข้อมูลข่าวสาร',
    AddressStore: {
      companyName: 'บริษัท วันรัต (หน่ำเซียน) จำกัด',
      Address: '65/1 ถนนสุขาภิบาล 2 แขวงประเวศ เขตประเวศ กรุงเทพฯ ประเทศไทย 10250',
      Phone: 'เบอร์โทรศัพท์: (+66)2 036 9898',
      Email: 'อีเมล: nsgshopping@namsiang.com'
    },
    TitleSubFooter: {
      title1: 'ซื้อสินค้าสุดคุ้ม',
      title2: 'การชำระเงินที่ปลอดภัย',
      title3: 'การจัดส่ง',
      title4: 'บริการหลังการขาย'
    },
    textSubFooter: {
      text1: 'เลือกสินค้าที่คุณต้องการบนเว็บไซต์ของเรา พร้อมส่วนลดพิเศษเสมอ',
      text2: 'ชำระเงินด้วยวิธีการชำระเงินที่ปลอดภัยที่สุด หลากหลายและมีความยืดหยุ่น',
      text3: 'เราจัดส่งสินค้าของคุณ ให้ถึงมืออย่างรวดเร็วและปลอดภัย',
      text4: 'บริการหลังการขายที่มีประสิทธิภาพ ที่จะทำให้คุณมั่นใจในสินค้าของเรา'
    }
  },
  AboutUs: {
    AboutUs: 'เกี่ยวกับเรา',
    Vision1: 'กลุ่มบริษัทหน่ำเซียนยืนหยัดในด้านคุณภาพสินค้าและบริการมากกว่า 90 ปี มุ่งมั่นเพื่อการเติบโตของทางธุรกิจของลูกค้า',
    Vision2: 'ซึ่งเป็นเป้าหมายหลักในการทำงานของเรา',
    year2470: 'พ.ศ. 2470',
    detail2470: 'หน่ำเซียนได้ก่อตั้งขึ้นจากการเปิดร้านในย่านจักรวรรดิ เยาวราช โดยเริ่มต้นธุรกิจผลิตน้ำมันใส่ผมกลิ่นมะลิยี่ห้อ "มะลิสด" ซึ่งต่อมา ดอกมะลิจึงกลายมาเป็นสัญลักษณ์ของกลุ่มบริษัทหน่ำเซียนในปัจจุบัน',
    year2499: 'พ.ศ. 2499',
    detail2499: 'กลุ่มหน่ำเซียนผันตัวจากผู้ผลิตมาสู่การเป็นผู้จำหน่ายวัตถุดิบต่างๆเพื่อผลิตในอุตสาหกรรมเครื่องสำอาง อาหารและยา',
    year2538: 'พ.ศ. 2538',
    detail2538: 'กลุ่มบริษัทหน่ำเซียนพัฒนาระบบอย่างต่อเนื่องเพื่อรองรับการเติบโตของลูกค้าจึงได้รับความไว้วางใจในการเป็นผู้จัดจำหน่ายแก่บริษัทข้ามชาติชั้นนำหลายแห่งในประเทศไทย',
    year2552: 'พ.ศ. 2552',
    detail2552: 'กลุ่มบริษัทหน่ำเซียนได้เล็งเห็นถึงความสำคัญของการวิจัยและพัฒนาควบคู่ไปกับลูกค้า จึงได้ก่อตั้งศูนย์การวิจัยและพัฒนาเพื่อพัฒนาสูตรต้นแบบให้กับลูกค้ารวมถึงรองรับการตรวจสอบต่างๆ',
    year2553: 'พ.ศ. 2553-2558',
    detail2553: 'ในระยะเวลา 5 ปี กลุ่มบริษัทหน่ำเซียนได้ขยายสาขาเพื่อรองรับการเติบโตของอุตสาหกรรมเครื่องสำอาง อาหารและยาในภูมิภาคเอเชียตะวันออกเฉียงใต้',
    year2559: 'พ.ศ. 2559',
    detail2559: 'เพื่ออำนวยความสะดวกแก่ลูกค้ากลุ่มบริษัทหน่ำเซียนได้ขยายสาขาไปที่จังหวัดเชียงใหม่เพื่อบริการลูกค้าในเขตภาคเหนือ รวมถึงภาคกลางตอนบน'
  },
  Payment: {
    HowToBuyText: 'วิธีการสั่งซื้อวิธีชำระ',
    Option: 'ช่องทางการชำระเงิน',
    Banking: 'การโอนผ่านบัญชีธนาคาร',
    Online: 'ชำระผ่านช่องทางออนไลน์ Online Payment Service',
    Shipping: 'ช่องทางการจัดส่ง',
    Sending: 'กำหนดราคาจัดส่งตายตัว (Flat Rate) - จัดส่งภายใน 2 วันทำการ',
    Deliveryfee: 'ค่าจัดส่ง',
    Weight: 'ตามน้ำหนักรวมในตะกร้าสินค้า - จัดส่งภายใน 3 วันทำการ - น้ำหนักรวมไม่เกิน (Gram) 50,000',
    less: 'น้ำหนักรวมไม่เกิน',
    between: 'น้ำหนักรวมระหว่าง',
    more: 'น้ำหนักรวมมากกว่า'
  },
  LoginPage: {
    LoginWith: 'เข้าสู่ระบบด้วย',
    OneID: 'เข้าสู่ระบบด้วย ONE ID',
    Facebook: 'เข้าสู่ระบบด้วย Facebook',
    Line: 'เข้าสู่ระบบด้วย LINE',
    Google: 'เข้าสู่ระบบด้วย Google',
    Username: 'ชื่อผู้ใช้หรืออีเมลของคุณ',
    password: 'รหัสผ่านของคุณ',
    DoNotAccount: 'คุณยังไม่มีบัญชีผู้ใช้ ใช่หรือไม่?',
    Register: 'สมัครสมาชิก',
    Login: 'เข้าสู่ระบบ',
    ForgetPassword: 'ลืมรหัสผ่าน',
    RememberMe: 'จดจำฉันไว้ในระบบ'
  },
  AccountSetting: {
    logout: 'ออกจากระบบ',
    Account: {
      MyAccount: 'ข้อมูลส่วนตัว',
      MyProfile: 'ข้อมูลส่วนตัว',
      Username: 'ชื่อผู้ใช้',
      Name: 'ชื่อ',
      Email: 'อีเมล',
      PhoneNumber: 'เบอร์โทรศัพท์',
      EditProfile: 'แก้ไขข้อมูล'
    },
    Addresses: {
      Addresses: 'ที่อยู่ในการจัดส่งสินค้า',
      TaxAddresses: 'ที่อยู่ในการออกใบกำกับภาษี',
      AddAddress: 'เพิ่มที่อยู่ใหม่'
    },
    Order: {
      MyOrder: 'รายการสั่งซื้อสินค้าของฉัน'
    },
    HistoryPurchase: {
      HistoryPurchase: 'ประวัติการสั่งซื้อสินค้า',
      search: 'ค้นหา'
    },
    Vouchers: {
      MyVouchers: 'เวาเชอร์ของฉัน',
      Vouchers: 'เวาเชอร์'
    },
    Member: {
      Member: 'สมาชิกและสิทธิพิเศษ'
    },
    MyFavorite: {
      MyFavorite: 'รายการสินค้าที่ถูกใจ'
    },
    ReTurn: {
      MyReturn: 'รายการคืนสินค้าของฉัน'
    },
    Tracking: {
      MyTracking: 'ติดตามสถานะการจัดส่ง'
    },
    Quantity: {
      MyQuantity: 'การขอใบเสนอราคา'
    },
    OrderRating: {
      MyOrderRating: 'คะแนนคำสั่งซื้อของฉัน'
    }
  },
  ShoppingCart: {
    table: {
      Detail: 'รายละเอียดสินค้า',
      UnitPrice: 'ราคาต่อชิ้น',
      Quantity: 'จำนวน',
      TotalPrice: 'ราคารวม',
      Action: 'จัดการ',
      Unit: 'หน่วยนับ'
    },
    DeleteSelect: 'ลบสินค้าที่เลือก',
    OrderSummary: 'สรุปรายการสั่งซื้อสินค้า',
    ExcludingVAT: 'ราคาไม่รวมภาษีมูลค่าเพิ่ม',
    Discount: 'ใช้โค้ดส่วนลด / คูปอง',
    DiscountCurrently: 'ส่วนลดที่ใช้อยู่ตอนนี้',
    Code: 'เพิ่มส่วนลด',
    VAT: 'ภาษีมูลค่าเพิ่ม',
    MemberDiscount: 'ส่วนลดสมาชิก',
    EmpDiscount: 'ส่วนลดพนักงาน',
    WordDiscount: 'ส่วนลด',
    IncludingVAT: 'รวมภาษีมูลค่าเพิ่ม',
    totalprice: 'ราคารวมทั้งหมด',
    Shipping: 'การขนส่ง',
    ShippingCost: 'ค่าจัดส่ง',
    SelectShipping: 'เลือกการจัดส่ง',
    SelectPayment: 'วิธีการชำระเงิน',
    remark: 'ระบุความต้องการเพิ่มเติม',
    Point: 'แต้ม',
    PointCount: 'แต้มสะสมที่ได้ครั้งนี้',
    RewardPoint: 'แต้มสะสมที่ได้ครั้งนี้',
    Pay: 'ชำระเงิน',
    confirmOrder: 'ยืนยันคำสั่งซื้อ',
    NotSelectShipping: 'คุณยังไม่ได้เลือกขนส่ง กรุณาเลือกขนส่ง',
    OrderList: 'รายการสั่งซื้อสินค้า',
    OrderDocumentation: 'เอกสารประกอบการสั่งซื้อ',
    All: 'ทั้งหมด',
    ShippingAddress: 'ที่อยู่ในการจัดส่งสินค้า',
    AddShippingAddress: 'เพิ่มที่อยู่ในการจัดส่งสินค้า',
    TaxAddress: 'ที่อยู่ในการออกใบกำกับภาษี',
    AddTaxAddress: 'เพิ่มที่อยู่ในการออกใบกำกับภาษี',
    EditAddress: 'แก้ไขที่อยู่',
    EditTaxAddress: 'แก้ไขที่อยู่ในการออกใบกำกับภาษี',
    ProductList: 'รายการสินค้า',
    Pickupdate: 'เลือกวันที่รับ',
    Choosetoreceiveproducts: 'เลือกรับสินค้า',
    Pickupattheshop: 'รับสินค้าหน้าร้าน',
    ChakkrawatBranch: 'สาขาจักรวรรดิ์',
    ChiangMaiBranch: 'สาขาเชียงใหม่',
    Promotion: 'สินค้าโปรโมชัน',
    Free: 'สินค้าแถม',
    Receivingtaxinvoice: 'รับใบกำกับภาษี',
    Notreceivingtaxinvoice: 'ไม่รับใบกำกับภาษี',
    Email: 'อีเมล',
    Phone: 'เบอร์มือถือ',
    PhoneNumber: 'เบอร์โทรศัพท์',
    EditContactInformation: 'แก้ไขข้อมูลติดต่อ',
    MsgAlert: {
      PriceSomeProductHaveChanged: 'สินค้าบางรายการมีการเปลี่ยนแปลงราคา',
      FlashSaleSomeProductHaveChanged: 'สินค้าแฟลชเซลล์บางรายการมีการเปลี่ยนแปลง',
      CreateOrderSuccess: 'สั่งสินค้าเรียบร้อย',
      NSGClosed: 'ขออภัย NSG กำลังปิดปรับปรุง',
      GetQRCodeFail: 'แจ้งระบบรับชำระเงินขัดข้องชั่วคราว ขออภัยในความไม่สะดวกมา ณ ที่นี้'
    }
  },
  CampaingPromotion: {
    title: 'แคมเปญและโปรโมชัน',
    Detail: 'รายละเอียดแคมเปญ',
    start: 'วันที่เริ่ม',
    end: 'ไม่มีวันที่สิ้นสุด'
  }
}
