export default {
  default: 'en',
  SystemBar: {
    call: 'Call',
    time: 'Monday- Friday: 8:30 AM to 17:30 PM Closes:Saturday - Sunday'
  },
  Home: {
    serchBarText: 'Search Product',
    AllCategories: 'All Categories',
    SearchText: 'Search',
    HomeText: 'Home',
    AboutUsText: 'About Us',
    HowToBuyText: 'How to buy',
    ArticlesText: 'All articles',
    ContactText: 'Contact Us',
    CampaingPromotion: 'Campaign & Promotion',
    category: 'Categories',
    buyAgain: 'Buy Again',
    seeAll: 'See all',
    New: 'New',
    Hot: 'Hot',
    Recommended: 'Recommended',
    Promotion: 'Promotion',
    Campaing: 'Campaign',
    NewsAndActivity: 'Archives',
    NewsAndActivityAbout: 'Articles Reviews',
    ShoppingCart: 'Shopping Cart',
    CheckOut: 'Check Out',
    ViewMyShoppingCart: 'View My Shopping Cart',
    NoProductsYet: 'No Products Yet',
    GotoShopping: "Let's go shopping",
    YOUMAYALSOLIKE: 'YOU MAY ALSO LIKE',
    Voucher: 'Vouchers',
    UnpaidOrder: 'Unpaid Order',
    TrackingOrder: 'Tracking Order',
    RequestForQuotation: 'Request for Quotation',
    ToRate: 'To Rate',
    Comment: 'Comment / Customer Satisfaction',
    CommentBr: 'Comment',
    CustomerSatisfaction: 'Customer Satisfaction'
  },
  Footer: {
    AddressText: 'Store address',
    GetToKnowUs: 'Get to know us',
    head1: 'About Us',
    head2: 'How to buy',
    head3: 'My orders',
    head4: 'Store rating',
    head5: 'News And Activities',
    head6: 'Contact Us',
    head7: 'Term of use',
    head8: 'Deleting user data',
    head9: 'Private Policy',
    Payment: 'Payment options',
    Contact: 'News & Information',
    AddressStore: {
      companyName: 'Namsiang Co., Ltd.(NSG)',
      Address: '65/1 Sukhaphiban 2 Rd, Khwaeng Prawet, Prawet, Bangkok 10250',
      Phone: 'Phone : (+66)2 036 9898',
      Email: 'Email : nsgshopping@namsiang.com'
    },
    TitleSubFooter: {
      title1: 'Buy good value products',
      title2: 'Secure payment',
      title3: 'Delivery',
      title4: 'Service'
    },
    textSubFooter: {
      text1: 'Select the products you want on our website with constant promotions.',
      text2: 'Pay with the most safe and secure method using a variety of flexible options.',
      text3: 'We deliver straight to you quickly and safely.',
      text4: 'Effective service after purchase so you can have confidence in our products.'
    }
  },
  AboutUs: {
    AboutUs: 'ABOUT US',
    Vision1: 'Celebrating 90 years services to Personal Care,',
    Vision2: 'Food and Food Supplements industry in 2017',
    year2470: '1927 - 1934',
    detail2470: 'Namsiang first established as a manufacturer and retailer of cosmetics in Chakrawat Chinatown',
    year2499: '1958',
    detail2499: 'Namsiang ceased operating as a manufacturer and focused on becoming one of the leading suppliers of ingredients to the markets served.',
    year2538: '1995',
    detail2538: 'Namsiang has been appointed to import ingredients to supply to key MNC customers in Thailand',
    year2552: '2009',
    detail2552: 'New R&D Center was established to expand our support providing formulation guideline and prototypes for our valued customers.',
    year2553: '2010 - 2015',
    detail2553: 'Expand operations to include Vienam, Philippines, Indonesia. Provide local ex-stock support with repacking capability and technical support from HQ',
    year2559: '2016',
    detail2559: 'Namsiang rebranded to NSG to match with our vision to go Beyond Collaboration and become the supplier of choice in our chosen market in ASEAN'
  },
  Payment: {
    HowToBuyText: 'How to Buy',
    Option: 'Payment options',
    Banking: 'Bank/money transfer',
    Online: 'Online Payment Service',
    Shipping: 'Shipping options',
    Sending: 'Flat rate shipping - Delivery within 2 working days',
    Deliveryfee: 'Delivery fee',
    Weight: 'Weight-based shipping - Delivery within 3 working days - Total weight less than (Gram) 50,000',
    less: 'Weight less than or equal to',
    between: 'Weight between',
    more: 'Weight more than'
  },
  LoginPage: {
    LoginWith: 'Log in with',
    OneID: 'Log in with ONE ID',
    Facebook: 'Log in with Facebook',
    Line: 'Log in with LINE',
    Google: 'Log in with Google',
    Username: 'Username or Email',
    password: 'Password',
    DoNotAccount: 'Don’t have an account?',
    Register: 'Register',
    Login: 'Log in',
    ForgetPassword: 'Forgot password',
    RememberMe: 'Remember me'
  },
  AccountSetting: {
    logout: 'Logout',
    Account: {
      MyAccount: 'My Account',
      MyProfile: 'My Profile',
      Username: 'Username',
      Name: 'Name',
      Email: 'Email',
      PhoneNumber: 'Phone Number',
      EditProfile: 'Edit Profile'
    },
    Addresses: {
      Addresses: 'My Addresses',
      TaxAddresses: 'Tax Addresses',
      AddAddress: 'Add Address'
    },
    Order: {
      MyOrder: 'My Purchase'
    },
    HistoryPurchase: {
      HistoryPurchase: 'My History',
      search: 'Search'
    },
    Vouchers: {
      MyVouchers: 'My Vouchers',
      Vouchers: 'Vouchers'
    },
    Member: {
      Member: 'Member & Privilege'
    },
    MyFavorite: {
      MyFavorite: 'My Favorite'
    },
    ReTurn: {
      MyReturn: 'รายการคืนสินค้าของฉัน'
    },
    Tracking: {
      MyTracking: 'My Tracking Order'
    },
    Quantity: {
      MyQuantity: 'My Qoutation'
    },
    OrderRating: {
      MyOrderRating: 'My Order Rating'
    }
  },
  ShoppingCart: {
    table: {
      Detail: 'Detail Product',
      UnitPrice: 'Unit Price',
      Quantity: 'Quantity',
      TotalPrice: 'Total Price',
      Action: 'Action',
      Unit: 'Unit'
    },
    DeleteSelect: 'Delete Product',
    OrderSummary: 'Order Summary',
    ExcludingVAT: 'Excluding VAT',
    Discount: 'Code / Coupon',
    DiscountCurrently: 'Current Discounts',
    Code: 'Code / Coupon',
    VAT: 'VAT',
    MemberDiscount: 'Member Discount',
    EmpDiscount: 'Employee Discount',
    WordDiscount: 'Discount',
    IncludingVAT: 'Including VAT',
    totalprice: 'Total Price',
    Shipping: 'Shipping',
    ShippingCost: 'Shipping Fee',
    SelectShipping: 'Select Shipping',
    SelectPayment: 'Payment',
    remark: 'Remark',
    Point: 'Point',
    RewardPoint: 'Reward Point',
    Pay: 'PLACE ORDER NOW',
    confirmOrder: 'Confirm Order',
    NotSelectShipping: "You haven't chosen. Please choose a transport.",
    OrderList: 'Products Ordered',
    OrderDocumentation: 'Order Documentation',
    All: 'All',
    ShippingAddress: 'Delivery Address',
    AddShippingAddress: 'Add Delivery Address',
    TaxAddress: 'Tax Invoice',
    AddTaxAddress: 'Add Tax Invoice',
    EditAddress: 'Edit Address',
    EditTaxAddress: 'Edit Tax Invoice',
    ProductList: 'product lists',
    Pickupdate: 'Pick up date',
    Choosetoreceiveproducts: 'Choose to receive products',
    Pickupattheshop: 'Pick up at the shop',
    ChakkrawatBranch: 'Chakkrawat Branch',
    ChiangMaiBranch: 'Chiang Mai Branch',
    Promotion: 'Promotion',
    Free: 'Free',
    Receivingtaxinvoice: 'Receiving tax invoice',
    Notreceivingtaxinvoice: 'Not receiving tax invoice',
    Email: 'Email',
    Phone: 'Phone Number',
    PhoneNumber: 'Phone Number',
    EditContactInformation: 'Edit Contact Information',
    MsgAlert: {
      PriceSomeProductHaveChanged: 'Prices for some products have changed',
      FlashSaleSomeProductHaveChanged: 'Flash Sale for some products have changed',
      CreateOrderSuccess: 'Create Order Success',
      NSGClosed: 'Sorry, we are closed',
      GetQRCodeFail: 'Notification of a temporary malfunction of the payment system We apologize for the inconvenience.'
    }
  },
  CampaingPromotion: {
    title: 'Campaing & Promotion',
    Detail: 'campaign details',
    start: 'Start Date',
    end: 'Dateless'
  }
}
